import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    this.setDynamicTruncateLength();
    window.addEventListener('resize', this.setDynamicTruncateLength.bind(this));
  }

  disconnect() {
    window.removeEventListener('resize', this.setDynamicTruncateLength.bind(this));
  }

  initialize() {
    if (document.querySelector('.recruiter-about-logo') != null && $('.recruiter-about-logo')[0] == undefined) {
      $('.recruiter-about-birth-date')[0].classList.remove('recruiter-about-birth-date');
    }
  }

  setSearchAvailability(){
    $('#recruiters_filter_search, #jobs_filter_search').keyup(function() {
      let searchBtn = $('#search-bar-button')
      if(($(this).val().trim().length === '' || $(this).val().trim().length < 1)) {
        searchBtn.attr('disabled', true)
      }
      else {
        searchBtn.attr('disabled', false);
      }
    });
  }

  setDynamicTruncateLength() {
    const baseWidth = 751;
    const baseLength = 145;
    const maxTruncateLength = 550;
    const incrementWidth = 60;

    const screenWidth = window.innerWidth;
    let incrementLength;
    if (screenWidth > 1500) {
        incrementLength = 11;
    } else if (screenWidth > 1000) {
        incrementLength = 10;
    } else {
        incrementLength = 8;
    }

    const increments = Math.max(0, Math.floor((screenWidth - baseWidth) / incrementWidth));
    const truncateLength = Math.min(maxTruncateLength, baseLength + increments * incrementLength);

    document.querySelectorAll('.truncated').forEach(element => {
        const fullText = element.nextElementSibling.textContent;
        const truncatedText = fullText.slice(0, truncateLength);

        if (fullText.length > truncateLength + 20) {
            element.innerHTML = `${truncatedText}... <span class="read-more-btn-wrapper">
                <a href="javascript:void(0);" class="read-more-btn" data-action="click->profile#toggle"
                    data-truncated-id="${element.id}"
                    data-full-text-id="${element.nextElementSibling.id}">
                    <span class="more-icon">+</span><span class="more-button-text">more</span>
                </a>
            </span>`;
        } else {
            element.innerHTML = truncatedText;
        }
    });
}

  toggle(event) {
    const button = event.currentTarget;
    const truncatedId = button.getAttribute("data-truncated-id");
    const fullTextId = button.getAttribute("data-full-text-id");

    const truncatedText = document.getElementById(truncatedId);
    const fullText = document.getElementById(fullTextId);

    document.querySelectorAll(".review-container").forEach(container => {
        const otherTruncated = container.querySelector(".truncated");
        const otherFull = container.querySelector(".more-text");
        const otherButton = container.querySelector(".read-more-btn");

        if (otherFull && otherTruncated) {
            otherTruncated.classList.remove("hidden");
            otherFull.classList.add("hidden");
            otherFull.classList.remove("expanded");
        }

        if (otherButton) {
            otherButton.innerHTML = "+ more";
        }
    });

    const isExpanded = fullText.classList.toggle("expanded");
    truncatedText.classList.toggle("hidden", isExpanded);
    fullText.classList.toggle("hidden", !isExpanded);
    button.innerHTML = isExpanded ? "- less" : "+ more";
}

  setTabSession(event) {
    this.clearReviewShow();
    this.clearAboutPath();
    sessionStorage.removeItem('search');
    sessionStorage.removeItem('recruiter-search');

    const targetTab = event.currentTarget.dataset['target'];
    if (targetTab !== sessionStorage.getItem('tab')) {
      $('.clear-search-button').attr('disabled', true);
    }
    sessionStorage.setItem('tab', targetTab);

    const windowHref = window.location.href.split('?')[0];
    if (sessionStorage.pagination == 'selected' || window.location.href.includes('page=') && windowHref.includes('#recruiters-tab')) {
      if (['review-tab', 'job-tab', 'recruiter-tab'].includes(sessionStorage.tab)) {
        sessionStorage.removeItem('pagination');
        window.location.href = windowHref;
      }
    }
  }


  setTabSession(event) {
    this.clearReviewShow();
    this.clearAboutPath();
    sessionStorage.removeItem('search');
    sessionStorage.removeItem('recruiter-search');
    if (event.currentTarget.dataset['target'] != sessionStorage.getItem('tab')) {
      $('.clear-search-button').attr('disabled', true);
    }
    sessionStorage.setItem('tab', event.currentTarget.dataset['target']);
    var window_href = window.location.href;
    if (window_href.split('?')[1].indexOf('review_id') === 0 && window.location.hash === '#reviews-tab') {
      window.location.href = window_href.split('?')[0] + '#about-tab';
    }
    if (sessionStorage.pagination == 'selected' || (window_href.includes('page=') && window_href.includes('#recruiters-tab'))) {
      if (sessionStorage.tab == 'review-tab' || sessionStorage.tab == 'job-tab' || sessionStorage.tab == 'recruiter-tab') {
        sessionStorage.removeItem('pagination');
        window.location.href = window_href.split('?')[0];
      }
    }
  }

  clearReviewShow() {
    if (sessionStorage.getItem('about-overview')){
      sessionStorage.removeItem('about-overview');
      window.location.href = window.location.href.split('?')[0];
    }
  }

  setSearchSession() {
    sessionStorage.setItem('search', 'job-searched');
  }

  setSearchRecruiterSession() {
    sessionStorage.setItem('recruiter-search', 'recruiter-searched');
  }

  clearSearches() {
    sessionStorage.setItem('clear-search', 'clear');
    window.location.href = window.location.href.split('?')[0];
  }

  async ViewJob(event) {
    event.preventDefault();
    var job_id;
    job_id = event.target.getAttribute('dataid');
    var url = new URL(window.location.href);
    url.searchParams.set('job_id', job_id);
    window.location.href = url.href;
    sessionStorage.setItem('jobs-to-about', 'true');
  }

  clearAboutPath() {
    if (sessionStorage.getItem('jobs-to-about')){
      sessionStorage.removeItem('jobs-to-about');
      window.location.href = window.location.href.split('?')[0];
    }
  }

  ViewRecruiter(event) {
    var recruiter_url = event.currentTarget.getAttribute('data-url');
    sessionStorage.removeItem('tab');
    window.open(recruiter_url, '_blank');
    sessionStorage.setItem('tab', 'recruiter-tab');
  }

  setClickableSort(event) {
    var sort_url = $(event.target).find("a").attr("href");
    this.setSearchRecruiterSession();

    if (window.location.href.split('#')[0].indexOf("companies") === -1) {
      window.location.href = window.location.href.split('#')[0] + sort_url + '#recruiters-tab'
    } else {
      window.location.href = window.location.href.split('#')[0].substring(0, window.location.href.split('#')[0].indexOf("companies")) + sort_url + '#recruiters-tab'
    }
  }

  async ViewReferral(event) {
    let url;
    url = event.currentTarget.dataset['target'];
    window.open(url, '_self');
  }

  async UpVoteReview(event) {
    event.preventDefault();
  }

  async BackToList(event) {
    let url;
    url = event.currentTarget.dataset['target'];
    if (url !== '') {
      window.open(url, '_self');
    } else {
      history.back();
    }
  }

  async CopyLink(event) {
    event.preventDefault();
    var url = event.currentTarget.dataset['target'];
    if (url !== '') {
      var dummy = document.createElement('input'),
      text =  window.location.href.split('/jobs')[0]+url;
    }
    else{
      var dummy = document.createElement('input'),
      text = window.location.href;
    }
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    var copy_alert = $('#copy');
    copy_alert.fadeIn('slow');
    copy_alert.fadeOut(5000);
  }

  async ReportReview(event) {
    event.preventDefault();
    openModal('#review-flagged-modal');
  }
}
