import { Controller } from "stimulus";
import 'foundation-sites';
import $ from "jquery";

export default class extends Controller {
  static targets = ["menu", "options"];

  connect() {
    this.selectedItem = null;
    this.jobBox = document.querySelector(".dotted-box-location");
    this.jobTextComponent = document.querySelector(".job-text-selection-location");
    this.jobIcon = document.querySelector(".add-job-location");
    this.jobTextElement = document.getElementById("selected-location-types");
    this.applyButton = this.element.querySelector(".locations-apply-button");
    this.hintText = document.getElementById("hintText");
    this.searchImage = document.querySelector(".search-image");
    $(this.menuTarget).foundation();
    document.addEventListener('click', this.handleClickOutside.bind(this));
    this.extractAndApplySelection();
    this.hintText.classList.add("hidden")
    this.debounceTimeout = null;
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside.bind(this));
  }

  handleClickOutside(event) {
    const dropdown = this.menuTarget;
    const jobBox = document.querySelector(".recruiters-location-dropdown");

    if (!dropdown.contains(event.target) && !jobBox.contains(event.target)) {
      this.closeDropdown();
    }
  }

  closeDropdown() {
    this.hintText.classList.add("hidden");
    $(this.menuTarget).foundation('close');
    this.jobTextElement.classList.remove("opacity");
    this.jobIcon.classList.remove("opacity");
  }

  async searchLocations(event) {
    const query = event.target.value;

    if (query.trim().length > 0) {
      this.searchImage.classList.add("hidden");
    } else {
      this.searchImage.classList.remove("hidden");
    }

    clearTimeout(this.debounceTimeout);

    this.debounceTimeout = setTimeout(async () => {
      if (query.trim().length > 0) {
        this.hintText.classList.add("hidden");
        const suggestions = await this.fetchGoogleSuggestions(query.trim());
        this.updateOptions(suggestions);
      } else {
        this.optionsTarget.innerHTML = "";
        this.hintText.classList.remove("hidden");
      }

      this.toggleApplyButton(false);
    }, 300);
  }

  updateOptions(suggestions) {
    const optionsContainer = this.optionsTarget;
    optionsContainer.innerHTML = "";

    suggestions.forEach((suggestion) => {
      const optionElement = document.createElement("div");
      optionElement.classList.add("job-location-option");
      optionElement.textContent = suggestion;
      optionElement.setAttribute("data-action", "click->locations-marketplace#selectOption");
      optionsContainer.appendChild(optionElement);
    });
  }

  selectOption(event) {
    const jobType = event.currentTarget.textContent.trim();
    if (this.selectedItem) {
      const previousSelection = document.querySelector(`.job-location-option.selected`);
      if (previousSelection) {
        previousSelection.classList.remove('selected');
      }
    }

    this.selectedItem = jobType;
    event.currentTarget.classList.add('selected');
    this.toggleApplyButton(true);
  }

  async fetchGoogleSuggestions(query) {
    const response = await fetch(`/google_places/autocomplete?term=${encodeURIComponent(query)}`);
    if (response.ok) {
      const data = await response.json();
      return data.places || [];
    } else {
      return [];
    }
  }

  openDropdown() {
    $(this.menuTarget).foundation('open');
    this.jobTextElement.classList.add("opacity");
    this.jobIcon.classList.add("opacity");
    if (document.getElementById("searchInput").value == "") {
      this.hintText.classList.remove("hidden");
    }
  }

  clearSelections(reset = true) {
    this.selectedItem = null;
    this.updateButtonText(true);
    this.jobBox.classList.remove("selected");
    if (reset === true) {
      this.hintText.classList.add("hidden");
    }
    else {
      this.hintText.classList.remove("hidden");
    }
  }

  clearOptions() {
    this.hintText.classList.add("hidden");
    this.clearSelections(true);
    $(this.menuTarget).foundation('close');
    this.toggleApplyButton(false);
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete("locations");
    window.location.href = currentUrl.toString();
  }

  cancelOptions() {
    document.getElementById("searchInput").value = "";
    const optionsContainer = this.optionsTarget;
    optionsContainer.innerHTML = "";
    this.selectedItems = [];
    this.toggleApplyButton(false);
    this.hintText.classList.add("hidden");
    this.searchImage.classList.remove("hidden");
    $(this.menuTarget).foundation('close');
    this.jobTextElement.classList.remove("opacity");
    this.jobIcon.classList.remove("opacity");
  }

  updateButtonText(isClear = false) {
    if (isClear || !this.selectedItem) {
      this.jobTextElement.innerHTML = "Any";
      this.jobTextComponent.style.marginLeft = "0.438rem";
      this.jobBox.style.justifyContent = "center";
      document.querySelector("button.dotted-box-location span img").style.display = "inline";
      this.toggleApplyButton(false);
    } else {
      this.jobTextElement.classList.remove("opacity");
      this.jobIcon.classList.remove("opacity");
      this.jobBox.style.justifyContent = "flex-start";
      this.jobBox.style.padding = "0.2rem 0.23rem";
      this.jobTextComponent.style.width = "100%";
      this.jobTextComponent.style.display = "flex";
      this.jobTextComponent.style.marginLeft = "0";
      this.jobTextComponent.style.gap = "0.2rem";
      document.querySelector("button.dotted-box-location span img").style.display = "none";
      this.jobTextElement.innerHTML = `<span class="selected-location">${this.selectedItem}</span>`;
    }
  }

  toggleApplyButton(enable) {
    if (enable) {
      this.applyButton.removeAttribute("disabled");
      this.applyButton.classList.remove("disabled");
    } else {
      this.applyButton.setAttribute("disabled", "disabled");
      this.applyButton.classList.add("disabled");
    }
  }

  applySelection(event) {
    if (!this.selectedItem) {
      $(this.menuTarget).foundation('open');
    } else {
      this.jobTextElement.classList.remove("opacity");
      this.jobIcon.classList.remove("opacity");
      this.jobBox.classList.remove("selected");
      $(this.menuTarget).foundation('close');
    }

    this.locationsRedirection(event);
  }

  locationsRedirection(event) {
    if (this.selectedItem) {
      const selectedLocation = this.selectedItem;
      const formattedLocation = selectedLocation.replace(/%20/g, "+");
      const currentUrl = new URL(window.location.href);
      const searchParams = currentUrl.searchParams;
      searchParams.set("locations", formattedLocation);
      currentUrl.search = searchParams.toString();
      window.location.href = currentUrl.toString();
    }
  }

  extractAndApplySelection() {
    const urlParams = new URLSearchParams(window.location.search);
    const location = urlParams.get("locations");
    if (location) {
      this.selectedItem = decodeURIComponent(location.replace(/\+/g, " "));
      this.updateButtonText();
    }
  }
}
