import { Controller } from 'stimulus';
import ValidatorFactory from '../validators/factory';
import { openModal } from '../components/modal';

export default class extends Controller {

  connect() {
    const showIndividualOnboardingModal = document.getElementById("modal-review-type-wrapper").dataset.showIndividualOnboarding;
    const showCompanyOnboardingModal = document.getElementById("modal-review-type-wrapper").dataset.showCompanyOnboarding;

    const hasSeenModal = localStorage.getItem('hasSeenOnboardingModal');

    if (hasSeenModal !== 'true') {
      if (showIndividualOnboardingModal == 'true') {
          openModal(`#individual-onboarding`);
      } else if (showCompanyOnboardingModal == 'true') {
          openModal(`#company-onboarding`);
      }
      localStorage.setItem('hasSeenOnboardingModal', 'true');
    }
  }

  toggleNPS(event) {
    const npsContainer = this.targets.find('npsContainer');
    const npsInfo = this.targets.find('npsInfo');

    if (event.currentTarget.value === 'agency') {
      npsContainer.classList.remove('is-hidden');
      npsInfo.classList.remove('is-hidden');

      if (!npsContainer.classList.contains('margin-bottom')) {
        npsContainer.classList.add('margin-bottom');
      }
    } else {
      npsContainer.classList.add('is-hidden');
      npsInfo.classList.add('is-hidden');
    }
  }

  toggleReviewee(event) {
    const revieweeContainer = this.targets.find('revieweeContainer');
    const reviewee = this.targets.find('reviewee');

    if (event.currentTarget.value === 'internal' || event.currentTarget.value === 'thing') {
      let label = event.currentTarget.value === 'internal' ? 'internal-label' : 'thing-label'
      let placeHolder = event.currentTarget.value === 'internal' ? 'internal-placeholder' : 'thing-placeholder'
      revieweeContainer.querySelector('label').textContent = this.data.get(label);
      reviewee.placeholder = this.data.get(placeHolder);
      this.targets.find('departments').querySelector('label').textContent = this.data.get('teams-companies');
    } else {
      revieweeContainer.querySelector('label').textContent = this.data.get('label');
      reviewee.placeholder = this.data.get('placeholder');
      this.targets.find('departments').querySelector('label').textContent = this.data.get('teams-employees');
    }
  }

  destroy(event) {
    if (!this.validations(false)) {
      event.preventDefault();
    }
  }

  save(event) {
    if (!this.validations()) {
      event.preventDefault();
    }
  }

  toggleRemoveBtn() {
    this.targets.find('removeBtn').disabled = !this.validations(false);
  }

  validations(showErrorInElements = true) {
    let isValid = true;

    this.validates.forEach(validate => {
      const element = this.targets.find(validate);

      if (element) {
        const validations = element.dataset['validates'];

        if (validations) {
          validations.split(' ').forEach(validation => {
             const validator = ValidatorFactory.from(this, validation);
             if (!validator.isValid(element.value)) {
               isValid = false;
               showErrorInElements && element.classList.add('is-input-error');
            } else {
               showErrorInElements && element.classList.remove('is-input-error');
             }
          });
        }
      }
    });

    return isValid;
  }

  get validates() {
    const validates = this.data.get('validates');
    if (!validates) {
      return [];
    }

    return validates.split(' ');
  }

  limitReachedPopup(event) {
    openModal(`#contact-sales-modal`)
    const url = event.currentTarget.dataset['url']
    const review_print = event.currentTarget.dataset['reviewPrint']
    $.ajax(url, {
      method: 'get',
      data: { review_print_type: review_print }
    })
  }

  reviewCardInfoModal(event) {
    const id = event.currentTarget.dataset['id']
    openModal(`#review-card-info${id}`)
  }

  checkIndividualOnboarding(event){
    event.preventDefault();
    openModal(`#individual-onboarding`)
  }

  checkCompanyOnboarding(event){
    event.preventDefault();
    openModal(`#company-onboarding`)
  }

  toggleDropdown(event) {
    event.stopPropagation();

    const dropdown = document.getElementById("dropdown-menu");
    if (dropdown) {
      dropdown.classList.toggle("hidden");

      if (!dropdown.classList.contains("hidden")) {
        document.addEventListener("click", this.closeDropdown.bind(this));
      }
    }
  }

  closeDropdown(event) {
    const dropdown = document.getElementById("dropdown-menu");

    if (dropdown && !dropdown.classList.contains("hidden")) {
      dropdown.classList.add("hidden");
      document.removeEventListener("click", this.closeDropdown.bind(this));
    }
  }

  toggleDropdownAlt(event) {
    event.stopPropagation();

    const dropdown = document.getElementById("dropdown-menu-alt");
    if (dropdown) {
      dropdown.classList.toggle("hidden-alt");

      if (!dropdown.classList.contains("hidden-alt")) {
        document.addEventListener("click", this.closeDropdownAlt.bind(this));
      }
    }
  }

  closeDropdownAlt(event) {
    const dropdown = document.getElementById("dropdown-menu-alt");

    if (dropdown && !dropdown.classList.contains("hidden-alt")) {
      dropdown.classList.add("hidden-alt");
      document.removeEventListener("click", this.closeDropdownAlt.bind(this));
    }
  }
}
