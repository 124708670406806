import { Controller } from 'stimulus';

export default class extends Controller {
  connect () {
    const daySpan = document.querySelector('#reports_filter_day_span');
    const startDate = document.querySelector('#reports_filter_start_date');
    const endDate = document.querySelector('#reports_filter_end_date');
    const params = new URLSearchParams(window.location.search);

    if(startDate.value !== '' && endDate.value !== '' && params.get('commit') !== 'Find Range' && daySpan.value !== '' && daySpan.value !== '-1') {
      startDate.value = '';
      endDate.value = '';
    }

    if (daySpan.value !== '' && daySpan.value !== '-1') {
      startDate.disabled = true;
      endDate.disabled = true;
      $('#date-submit')[0].disabled = true;
    } else if(startDate.value !== '' && endDate.value !== '') {
      daySpan.disabled = true;
    }
  }

  submitBtn(event){
    var start_date_value = $('#reports_filter_start_date')[0].value
    var end_date_value = $('#reports_filter_end_date')[0].value

    if (start_date_value == '' && end_date_value == ''){
      $('#date-submit')[0].form.submit()
    }
    else if(start_date_value == '' || end_date_value == ''){
      event.preventDefault()
      alert('Please add missing Date as well')
    }
    else {
      var startDate = new Date(start_date_value)
      var endDate = new Date(end_date_value)

      if (startDate > endDate){
        event.preventDefault();
        alert('Start Date can not be greater than End Date')
      }
      else {
        $('#date-submit')[0].form.submit()
      }
    }
  }
}
