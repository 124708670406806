import { Controller } from "stimulus";
import "foundation-sites";
import $ from "jquery";

export default class extends Controller {
  static targets = ["role", "searchInput", "rolesList", "roleItem", "submenuTitle"];

  connect() {
    this.selectedRoles = [];
    this.category = {};
    this.lastRemoved = null;
    this.anotherCategoryDiv = null;
    const industry_name = window.location.hostname.split('.')[0]
    this.roleBox = document.querySelector(".dotted-box-roles");
    this.roleTextComponent = document.querySelector(".job-text-selection-roles");
    this.roleIcon = document.querySelector(".add-job-roles");
    this.roleTextElement = document.getElementById("selected-roles-types");
    this.applyButton = this.element.querySelector(".roles-apply-button");
    $(this.roleTarget).foundation();
    document.addEventListener("click", this.handleOutsideClick.bind(this));
    this.submenuTitleTargets.forEach((submenuTitle) => {
      submenuTitle.addEventListener('click', this.preventBackEvent.bind(this));
    });
    if (industry_name == 'healthcare') {
      this.updatedCategories();
    } else {
      this.updatedMainCategories();
    }
    this.updatedNewRoles();
    this.disableEnterKey();
  }

  disconnect() {
    document.removeEventListener("click", this.handleOutsideClick.bind(this));
  }

  preventBackEvent(event) {
    event.stopPropagation();
  }

  updatedNewRoles() {
    const selectedRolesUpdated = JSON.parse(localStorage.getItem('selectedRolesUpdated'));
    if (!selectedRolesUpdated) return;

    selectedRolesUpdated.forEach((subRole) => {
      this.selectedRoles.push(subRole);
      this.updateSelectedText();
    });
  }

  updatedCategories() {
    const queryParams = new URLSearchParams(window.location.search);
    const subCategoryFromParams = queryParams.getAll('specialties[]');

    if (subCategoryFromParams.length === 0) {
      localStorage.removeItem('updateCatergoryHash');
      localStorage.removeItem('selectedRolesUpdated');
      return;
    }

    const categoryUpdated = JSON.parse(localStorage.getItem('updateCatergoryHash'));
    if (Object.keys(categoryUpdated).length > 0) {
      this.category = categoryUpdated;

      subCategoryFromParams.forEach((subCategory) => {
        const mainCategory = categoryUpdated[subCategory];
        if (mainCategory) {
          this.addRoleToDisplay(mainCategory, subCategory);
        }
      });
    } else {
      this.category = {};
    }
  }

  updatedMainCategories() {
    const queryParams = new URLSearchParams(window.location.search);
    const subCategoryFromParams = queryParams.getAll('specialties[]');

    if (subCategoryFromParams.length === 0) {
      localStorage.removeItem('updateCatergoryHash');
      localStorage.removeItem('selectedRolesUpdated');
      return;
    }

    const categoryUpdated = JSON.parse(localStorage.getItem('updateCatergoryHash'));
    if (Object.keys(categoryUpdated).length > 0) {
      this.category = categoryUpdated;

      subCategoryFromParams.forEach((subCategory) => {
        const mainCategory = categoryUpdated[subCategory];
        if (mainCategory) {
          this.addMainRoleToDisplay(subCategory);
        }
      });
    } else {
      this.category = {};
    }
  }

  filterRoles(event) {
    const row = event.currentTarget.closest(".roles-main");
    const searchInput = row.querySelector(".role-search-input");
    const searchTerm = searchInput.value.toLowerCase();
    const searchIcon = row.querySelector(".search-icon");
    const roleSubs = row.querySelectorAll(".roles-sub-text");
    let hasResults = false;
    searchIcon.style.display = searchTerm.length > 0 ? 'none' : '';

    roleSubs.forEach((roleSub) => {
      const roleText = roleSub.textContent.toLowerCase();

      if (roleSub.classList.contains("selected")) {
        roleSub.style.display = "none";
      } else {
        if (roleText.includes(searchTerm.trim())) {
          roleSub.style.display = "";
          hasResults = true;
        } else {
          roleSub.style.display = "none";
        }
      }
    });
    this.noResultsMessage(hasResults,row);
  }

  disableEnterKey() {
    document.addEventListener("keydown", (e) => {
      if (e.target.matches(".role-search-input") && e.keyCode === 13) {
        e.preventDefault();
      }
    });
  }

  noResultsMessage(hasResults, row) {
    const noMatchMessageClass = "no-match-message";
    const messageContainer = row.querySelector(".roles-sub-category-height");
    let noMatchMessage = row.querySelector(`.${noMatchMessageClass}`);

    if (!hasResults) {
      if (!noMatchMessage) {
        noMatchMessage = document.createElement("div");
        noMatchMessage.classList.add(noMatchMessageClass);
        noMatchMessage.textContent = "No matching roles found";

        Object.assign(noMatchMessage.style, {
          fontSize: "1.188rem",
          marginTop: "0.5rem",
          color: "black",
          textAlign: "center",
          fontWeight: "700",
        });

        messageContainer.appendChild(noMatchMessage);
        messageContainer.setAttribute("aria-hidden", "true");
      }
    } else {
      if (noMatchMessage) {
        noMatchMessage.remove();
        messageContainer.removeAttribute("aria-hidden");
      }
    }
  }

  toggleRole(event) {
    const roleType = event.currentTarget.dataset.roleType;
    const roleItem = event.currentTarget;
    const mainCategory = event.currentTarget.closest(".roles-main").querySelector(".roles-main-position").textContent.trim();
    const selectedIndex = this.selectedRoles.indexOf(roleType);
    const row = event.currentTarget.closest(".roles-main");
    const selectedLimit = document.querySelector('.selected-roles-container').querySelectorAll('.subcategory-item').length

    if (selectedIndex > -1) {
      this.selectedRoles.splice(selectedIndex, 1);
      roleItem.classList.remove('selected');
      localStorage.removeItem('selectedRolesUpdated');
      this.removeRoleFromDisplay(mainCategory, roleType);
      $(this.roleTarget).foundation("open");
    } else if ( selectedLimit < 3) {
      this.selectedRoles.push(roleType);
      roleItem.classList.add('selected');
      this.addCategoryData(roleType, mainCategory);
      this.resetSearchInputs(row);
      this.addRoleToDisplay(mainCategory, roleType);
      $('.back-link.back-button-styling').click();
      $(this.roleTarget).foundation("open");
    }

    if ( selectedLimit == 3){
      $('.back-link.back-button-styling').click();
    }
    this.updateApplyButtonVisibility();
  }

  updateLimitMessage(selectedLimit){
    const selectedRolesContainer = this.element.querySelector(".add-another");
    const existingAddAnother = selectedRolesContainer.querySelector(".another-category");
    if (existingAddAnother) existingAddAnother.remove();

    this.anotherCategoryDiv = document.createElement("div");
    this.anotherCategoryDiv.classList.add("another-category");

    this.anotherCategoryDiv.innerHTML =
    selectedLimit === 3 ? "Max 3 Roles allowed" : selectedLimit > 0 ? "Add another:" : "";
    selectedRolesContainer.appendChild(this.anotherCategoryDiv);
  }

  updateSelectedText(isClear = false) {
    const selectedRoleCount = isClear ? 0 :this.selectedRoles.length;

    if (selectedRoleCount === 0) {
      this.roleTextElement.innerHTML = "Any";
      this.roleTextComponent.style.marginLeft = "0.438rem";
      this.roleBox.style.justifyContent = "center";
      document.querySelector("button.dotted-box-roles span img").style.display = "inline";
    } else {
      this.lastRemoved = true;
      const selectedRolesToShow = this.selectedRoles.slice(0, 3);
      const roleText = selectedRolesToShow.map(role => `<span class="selected-role">${role}</span>`).join(" ");

      this.roleTextElement.classList.remove("opacity");
      this.roleIcon.classList.remove("opacity");
      this.roleTextComponent.style.width = "100%";
      this.roleBox.style.justifyContent = "flex-start";
      this.roleBox.style.padding = "0.2rem 0.23rem";
      this.roleTextComponent.style.display = "flex";
      this.roleTextComponent.style.marginLeft = "0";
      this.roleTextComponent.style.gap = "0.2rem";
      document.querySelector("button.dotted-box-roles span img").style.display = "none";
      this.roleTextElement.innerHTML = roleText;
    }
  }

  addRoleToDisplay(mainCategory, roleType) {
    let mainCategoryContainer = document.querySelector(`[data-category="${mainCategory}"]`);
    const subRole = this.element.querySelector(`.roles-sub-text[data-role-type="${roleType}"]`);
    const selectedLimit = document.querySelector('.selected-roles-container').querySelectorAll('.subcategory-item').length

    subRole.style.display = 'none';

    if (!mainCategoryContainer) {
      const container = document.createElement("div");
      container.dataset.category = mainCategory;
      container.classList.add("main-category-container");
      container.innerHTML = `
        <div class="category-title">${mainCategory}</div>
        <ul class="subcategory-list"></ul>
      `;
      this.element.querySelector(".selected-roles-container").appendChild(container);
      mainCategoryContainer = container;
    }

    const subcategoryList = mainCategoryContainer.querySelector(".subcategory-list");
    const subcategoryItem = document.createElement("li");
    subcategoryItem.classList.add("subcategory-item");
    subcategoryItem.dataset.roleType = roleType;
    subcategoryItem.innerHTML = `
    <label style="display: flex; line-height: 1.2rem; align-items: center;">
      <input id="checkbox-${roleType}" class="role-checkbox" type="checkbox" checked data-role-type="${roleType}" data-action="change->roles-marketplace#toggleCheckbox" />
      <span class="role-checkbox-text" onclick="event.preventDefault();">${roleType}</span>
    </label>
    `;

    subcategoryList.appendChild(subcategoryItem);

    this.updateLimitMessage(selectedLimit + 1)
  }

  removeRoleFromDisplay(mainCategory, roleType) {
    this.removeCategoryData(roleType);
    if (this.selectedRoles.length === 0 && this.anotherCategoryDiv) {
      this.anotherCategoryDiv.innerHTML = '';
     }
    const subRole = this.element.querySelector(`.roles-sub-text[data-role-type="${roleType}"]`);
    subRole.style.display = 'flex';

    const mainCategoryContainer = document.querySelector(`[data-category="${mainCategory}"]`);
    if (!mainCategoryContainer) return;

    const subcategoryItem = mainCategoryContainer.querySelector(`[data-role-type="${roleType}"]`);
    if (subcategoryItem) subcategoryItem.remove();

    const subcategoryList = mainCategoryContainer.querySelector(".subcategory-list");
    if (!subcategoryList.children.length) {
      mainCategoryContainer.remove();
    }
  }

  toggleCheckbox(event) {
    const roleCategory = event.currentTarget.dataset.roleType;
    const mainCategory = event.currentTarget.closest(".main-category-container").dataset.category;
    const subRole = this.element.querySelector(`.roles-sub-text[data-role-type="${roleCategory}"]`);
    const selectedLimit = document.querySelector('.selected-roles-container').querySelectorAll('.subcategory-item').length

    if (event.currentTarget.checked) {
      this.selectedRoles.push(roleCategory);
    } else {
      this.selectedRoles = this.selectedRoles.filter((role) => role !== roleCategory);
      subRole.classList.remove('selected');
      this.removeRoleFromDisplay(mainCategory, roleCategory);
    }

    this.updateLimitMessage(selectedLimit - 1)
    this.updateApplyButtonVisibility();
  }

  updateApplyButtonVisibility() {
    const applyButton = this.element.querySelector('.roles-apply-button');
    applyButton.style.display = this.selectedRoles.length > 0 ? 'inline-block' : 'none';
  }

  openDropdown() {
    this.selectedRole = document.querySelectorAll(".selected-role");
    const industry_name = window.location.hostname.split('.')[0]
    this.roleBox.classList.add("selected");

    $(this.roleTarget).foundation("open");
    const applyButton = this.element.querySelector('.roles-apply-button');
    const selectedUpdatedRole = JSON.parse(localStorage.getItem('selectedRolesUpdated'));
    if (!selectedUpdatedRole) {
      this.roleTextElement.classList.add("opacity");
      this.roleIcon.classList.add("opacity");
    }
    if (this.selectedRoles.length > 0) {
      this.selectedRole.forEach(function(role) {
        role.classList.add("opacity");
      });
      applyButton.style.display = 'inline-block';
    } else {
      applyButton.style.display = 'none';
    }

    if (industry_name != 'healthcare'){
      this.resetMainSearchInputs();
    }
  }

  closeDropdown() {
    this.selectedRole = document.querySelectorAll(".selected-role");
    this.roleBox.classList.remove("selected");

    if (this.selectedRoles.length > 0) {
      this.selectedRole.forEach(function(role) {
        role.classList.remove("opacity");
      });
    }
    this.roleTextElement.classList.remove("opacity");
    this.roleIcon.classList.remove("opacity");
    $(this.roleTarget).foundation("close");
  }

  handleOutsideClick(event) {
    const dropdown = this.roleTarget;
    if (!dropdown.contains(event.target) && !this.element.contains(event.target)) {
      this.closeDropdown();
    }
  }

  applySelection() {
    localStorage.setItem('selectedRolesUpdated', JSON.stringify(this.selectedRoles));

    if (window.location.href.includes('healthcare')) {
      if (Array.isArray(this.selectedRoles)) {
        const selectedRoles = [...new Set(this.selectedRoles)];
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.delete('specialties[]');
        selectedRoles.forEach(role => {
          currentUrl.searchParams.append('specialties[]', role);
        });
        currentUrl.hash = '';
        currentUrl.search = currentUrl.search.replace(/(\?|&)page=\d+/, '$1page=1')
        window.location.href = currentUrl.toString();
      }
    }

    if (this.selectedRoles.length === 0 && this.lastRemoved === true) {
      this.lastRemoved = false;
      this.updateSelectedText();
      this.roleTextElement.classList.remove("opacity");
      this.roleIcon.classList.remove("opacity");
      this.rolebBox.classList.remove("selected");
      $(this.roleTarget).foundation('close');
    }else if(this.selectedRoles.length === 0) {
      $(this.roleTarget).foundation('open');
    }else {
      this.lastRemoved = false;
      this.updateSelectedText();
      this.roleTextElement.classList.remove("opacity");
      this.roleIcon.classList.remove("opacity");
      this.roleBox.classList.remove("selected");
      $(this.roleTarget).foundation('close');
    }
  }

  clearSelections() {
    this.clearData();
    this.showSelectedJobs();
    this.updateSelectedText();
  }

  clearData() {
    const subRolesToDisplay = document.querySelectorAll(`.roles-sub-text`);
    subRolesToDisplay.forEach((subRole) => {
      subRole.style.display = 'flex';
    });

    if (this.anotherCategoryDiv) {
      this.anotherCategoryDiv.innerHTML = '';
     }

    this.roleTextElement.classList.remove("opacity");
    this.roleIcon.classList.remove("opacity");
    this.selectedRoles = [];
    this.lastRemoved = false;

    document.querySelectorAll('.roles-sub-text').forEach(item => {
      item.classList.remove('selected');
    });
    document.querySelectorAll('.subcategory-item').forEach((item) => {
      item.remove();
    });
    document.querySelectorAll('.main-category-container').forEach((container) => {
        container.remove();
    });
  }

  updateSubmenuTitle(event) {
    const inputBox = event.currentTarget.closest(".roles-main");

    this.adjustRolesHeight()
    this.resetSearchInputs(inputBox);

    const row = event.currentTarget.closest(".roles-main-position") || event.currentTarget.closest(".icon-height-next");
    const roleName = row.getAttribute("data-role-name");
    const submenuTitle = row.closest(".roles-main").querySelector("[data-target='roles-marketplace.submenuTitle']");
    submenuTitle.textContent = roleName;

    const roleTextElements = document.querySelectorAll(".job-text.no-left-margin.full-width.no-top-margin");

    roleTextElements.forEach(roleTextElement => {
      if (Object.keys(this.selectedRoles).length > 0) {
        roleTextElement.textContent = "Pick another Role:";
      } else {
        roleTextElement.textContent = "Pick a Role:";
      }
    });

    this.roleItemTargets.forEach((roleItem) => {
    const roleName = roleItem.textContent.trim();
    if (this.selectedRoles.includes(roleName)) {
       roleItem.classList.add("selected");
       roleItem.style.display = 'none';
      } else {
        roleItem.classList.remove("selected");
        roleItem.style.display = '';
      }
    });
    const target = event.currentTarget.closest(".roles-main");
    this.noResultsMessage(true, target);
  }

  adjustRolesHeight() {
    const heightTargets = document.querySelectorAll('.roles-sub-category-height');
    const rolesContainer = document.querySelector('.selected-roles-container');
    
    const selectedLimit = rolesContainer.querySelectorAll('.subcategory-item').length;
    const selectedMainCategory = rolesContainer.querySelectorAll('.main-category-container').length;
  
    const height = selectedMainCategory === 1
      ? selectedLimit === 1
        ? '24.5rem'
        : selectedLimit === 2
        ? '25.5rem'
        : selectedLimit === 3
        ? '26.5rem'
        : '18rem'
      : selectedMainCategory === 2
      ? selectedLimit === 2
        ? '27.5rem'
        : selectedLimit === 3
        ? '28.5rem'
        : '18rem'
      : selectedMainCategory > 2 && selectedLimit === 3
      ? '30.5rem'
      : '18rem';
  
    heightTargets.forEach((target) => {
      target.style.height = height;
    });
  }

  showSelectedJobs() {
    const urlParams = new URLSearchParams(window.location.search);
    const rolesTypes = Array.from(new Set(urlParams.getAll("specialties[]")));
    const industry_name = window.location.hostname.split('.')[0]

    if (rolesTypes.length === 0) return;

    let categoryUpdated = JSON.parse(localStorage.getItem('updateCatergoryHash')) || {};

    Object.keys(categoryUpdated).forEach((subCategory) => {
      const mainCategory = categoryUpdated[subCategory];

      if (rolesTypes.includes(subCategory)) {
        const mainCategory = categoryUpdated[decodeURIComponent(subCategory)];
        if (industry_name == 'healthcare') {
          this.addRoleToDisplay(mainCategory, subCategory);
        }
        else {
          this.addMainRoleToDisplay(subCategory);
        }
        this.selectedRoles.push(subCategory);

        const roleElement = document.querySelector(`[data-role-type='${subCategory}']`);
        if (roleElement) {
          roleElement.classList.add('selected');
        }
      } else {
        if (industry_name == 'healthcare') {
          this.removeRoleFromDisplay(mainCategory, subCategory);
        } else {
          this.removeMainRoleFromDisplay(subCategory);
        }
      }
    });
  }

  resetOptions() {
    this.clearData();
    this.updateSelectedText(true);
    const currentUrl = new URL(window.location.href);

    currentUrl.searchParams.delete("specialties[]");
    currentUrl.search = currentUrl.search.replace(/(\?|&)page=\d+/, '$1page=1')
    window.location.href = currentUrl.toString();
    this.roleBox.classList.remove("selected");
    $(this.roleTarget).foundation('close');
  }

  clearOptions() {
    this.clearSelections();
    this.roleBox.classList.remove("selected");
    $(this.roleTarget).foundation('close');
  }

  addCategoryData(subCategory, mainCategory) {
    if (!subCategory || !mainCategory) return;

    this.category[subCategory] = mainCategory;
    this.updateCategory();
  }

  addMainCategoryData(subCategory) {
    if (!subCategory) return;

    this.category[subCategory] = subCategory;
    this.updateCategory();
  }

  removeCategoryData(subCategory) {
    if (!(subCategory in this.category)) return;

    delete this.category[subCategory];
    this.updateCategory();
  }

  updateCategory() {
    localStorage.setItem("updateCatergoryHash", JSON.stringify(this.category));
  }

  resetSearchInputs(row) {
    const searchInput = row.querySelector(".role-search-input");
    searchInput && (searchInput.value = '');

    const searchIcon = row.querySelector(".search-icon");
    searchIcon && (searchIcon.style.display = '');

    const roleItems = row.querySelectorAll(".roles-sub-text");
    roleItems.forEach((item) => {
      item.style.display = item.classList.contains('selected') ? 'none' : '';
    });
  }

  resetMainSearchInputs() {
    const searchInput = document.querySelector(".main-roles-input");
    searchInput && (searchInput.value = '');

    const searchIcon = document.querySelector(".roles-main-icon");
    searchIcon && (searchIcon.style.display = '');

    const roleItems = document.querySelectorAll(".roles-main");
    roleItems.forEach((item) => {
      item.style.display = item.classList.contains('selected') ? 'none' : '';
    });
  }

  toggleMainRole(event) {
    const roleType = event.currentTarget.dataset.roleType;
    const roleItem = event.currentTarget;
    const selectedIndex = this.selectedRoles.indexOf(roleType);
    const selectedLimit = document.querySelector('.selected-main-roles-container').querySelectorAll('.subcategory-item').length

    if (selectedIndex > -1) {
      this.selectedRoles.splice(selectedIndex, 1);
      roleItem.classList.remove('selected');
      localStorage.removeItem('selectedRolesUpdated');
      this.removeMainRoleFromDisplay(roleType);
    } else if ( selectedLimit < 3) {
      this.selectedRoles.push(roleType);
      roleItem.classList.add('selected');
      this.addMainCategoryData(roleType);
      this.resetMainSearchInputs();
      this.addMainRoleToDisplay(roleType);
      $('.back-link.back-button-styling').click();
    }

    if ( selectedLimit == 3){
      $('.back-link.back-button-styling').click();
    }
    this.updateApplyButtonVisibility();
  }

  addMainRoleToDisplay(roleType) {
    const subRole = this.element.querySelector(`.roles-sub-text[data-role-type="${roleType}"]`);
    const selectedLimit = document.querySelector('.selected-main-roles-container').querySelectorAll('.subcategory-item').length
    subRole.style.display = 'none';
    const container = document.createElement("div");
    container.classList.add("main-category-container");
    container.innerHTML = `
      <div class="subcategory-roles-list"></div>
    `;
    this.element.querySelector(".selected-main-roles-container").appendChild(container);

    const subcategoryList = this.element.querySelector(".subcategory-roles-list");
    const subcategoryItem = document.createElement("li");
    subcategoryItem.classList.add("subcategory-item");
    subcategoryItem.dataset.roleType = roleType;
    subcategoryItem.innerHTML = `
    <label style="display: flex; line-height: 1.2rem; align-items: center; padding-left: 0.625rem;">
      <input id="checkbox-${roleType}" class="role-checkbox" type="checkbox" checked data-role-type="${roleType}" data-action="change->roles-marketplace#toggleMainCheckbox" />
      <span class="role-checkbox-text" onclick="event.preventDefault();">${roleType}</span>
    </label>
    `;

    subcategoryList.appendChild(subcategoryItem);
    this.updateLimitMessage(selectedLimit + 1)
  }

  removeMainRoleFromDisplay(roleType) {
    this.removeCategoryData(roleType);
    if (this.selectedRoles.length === 0 && this.anotherCategoryDiv) {
      this.anotherCategoryDiv.innerHTML = '';
     }
    const subRole = this.element.querySelector(`.roles-sub-text[data-role-type="${roleType}"]`);
    subRole.style.display = 'flex';

    if (this.selectedRoles.length == 0) {
      const element = document.getElementById("noMainResults");
      if (element) {
        element.classList.add("display-none");
      }
    }

    const subcategoryItem = this.element.querySelector(`[data-role-type="${roleType}"]`);
    if (subcategoryItem) subcategoryItem.remove();
  }

  toggleMainCheckbox(event) {
    const roleCategory = event.currentTarget.dataset.roleType;
    const subRole = this.element.querySelector(`.roles-sub-text[data-role-type="${roleCategory}"]`);
    const selectedLimit = document.querySelector('.selected-main-roles-container').querySelectorAll('.subcategory-item').length

    if (event.currentTarget.checked) {
      this.selectedRoles.push(roleCategory);
    } else {
      this.selectedRoles = this.selectedRoles.filter((role) => role !== roleCategory);
      subRole.classList.remove('selected');
      this.removeMainRoleFromDisplay(roleCategory);
    }

    this.updateLimitMessage(selectedLimit - 1)
    this.updateApplyButtonVisibility();
  }

  filterMainRoles() {
    const searchInput = document.querySelector(".main-roles-input");
    const searchTerm = searchInput.value.toLowerCase();
    const searchIcon = this.element.querySelector(".roles-main-icon");
    const roleSubs = document.querySelectorAll(".roles-sub-text");
    let hasResults = false;
  
    searchIcon.style.display = searchTerm.length > 0 ? "none" : "";
  
    roleSubs.forEach((roleSub) => {
      const roleText = roleSub.textContent.toLowerCase();
      const closestRoleMain = roleSub.closest(".roles-main");

      if (roleSub.classList.contains("selected")) {
        closestRoleMain.style.display = "none";
      } else {
        if (roleText.includes(searchTerm.trim())) {
          closestRoleMain.style.display = "";
          hasResults = true;
        } else {
          closestRoleMain.style.display = "none";
        }
      }
    });

    this.noResultsMainMessage(hasResults);
  }
  
  noResultsMainMessage(hasResults) {
    const noMatchMessageClass = "no-match-message";
    const messageContainer = document.querySelector(".roles-main-width");
    let noMatchMessage = document.querySelector(`.${noMatchMessageClass}`);

    if (!hasResults) {
      if (!noMatchMessage) {
        noMatchMessage = document.createElement("div");
        noMatchMessage.classList.add(noMatchMessageClass);
        noMatchMessage.textContent = "No matching roles found";

        Object.assign(noMatchMessage.style, {
          minHeight: "12rem",
          fontSize: "1.188rem",
          marginTop: "0.5rem",
          color: "black",
          textAlign: "center",
          fontWeight: "700",
        });

        messageContainer.appendChild(noMatchMessage);
        messageContainer.setAttribute("aria-hidden", "true");
      }
    } else {
      if (noMatchMessage) {
        noMatchMessage.remove();
        messageContainer.removeAttribute("aria-hidden");
      }
    }
  }
}
