import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    $('#industries-select').select2({
      width:'100%',
      placeholder: 'Select Industries',
    });

    $('#roles-select').select2({
      width: '100%'
    })

    $('#job-type-select').select2({
      width: '100%'
    })

    $('#placements-select').select2({
      width: '100%'
    })
  }
}
