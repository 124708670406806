import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.industryChange();

    $('#industries-select').on("change", () => {
      this.industryChange();
    });
  }

  async industryChange() {
    const selectIndustryField = document.querySelector('#industries-select');
    const selectedIndustries = Array.from(selectIndustryField.selectedOptions).map(option => option.value);
    const noJobTypeFoundElement = document.querySelector('.no-job-type-found');

    if(selectedIndustries.length !== 0) {
      noJobTypeFoundElement.classList.add('display-hidden');
    }

    const jobTypeArray = [];

    const industryjobTypeHashString = this.element.getAttribute("data-industry-job-types");
    const industryjobTypeHash = JSON.parse(industryjobTypeHashString);

    selectedIndustries.forEach(industry => {
      if (industryjobTypeHash[industry]) {
        jobTypeArray.push(...industryjobTypeHash[industry]);
      }
    });

    this.displayJobType(Array.from(new Set(jobTypeArray)).sort());
  }

  async displayJobType(jobType) {
    const displayJobTypeContainer = document.getElementById("job-type-select");
    const currentOptions = Array.from(displayJobTypeContainer.options);

    const removedOptions = [];

    currentOptions.forEach((option) => {
      if (!jobType.includes(option.value)) {
        option.selected = false;
        option.disabled = true;
        removedOptions.push(option);
        displayJobTypeContainer.removeChild(option);
      } else {
        option.disabled = false;
      }
    });

    jobType.forEach((type) => {
      const existingOption = Array.from(displayJobTypeContainer.options).find(option => option.value === type);

      if (!existingOption) {
        const option = document.createElement("option");
        option.value = type;
        option.textContent = type;
        displayJobTypeContainer.appendChild(option);
      }
    });

    const event = new Event("change");
    displayJobTypeContainer.dispatchEvent(event);
  }

  async placementSelected() {
    this.industryChange();
    const selectIndustryField = document.querySelector('#industries-select');
    const selectedIndustries = Array.from(selectIndustryField.selectedOptions).map(option => option.value);
    const noRolesFoundElement = document.querySelector('.no-job-type-found');

    if(selectedIndustries.length === 0) {
      noRolesFoundElement.classList.remove('display-hidden');
    } else {
      noRolesFoundElement.classList.add('display-hidden');
    }
  }
}
