import { Controller } from "stimulus";
import 'foundation-sites';
import $ from "jquery";

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    this.selectedItems = [];
    this.lastRemoved = null;
    this.jobBox = document.querySelector(".dotted-box");
    this.jobTextComponent = document.querySelector(".job-text-selection");
    this.jobIcon = document.querySelector(".add-job");
    this.jobTextElement = document.getElementById("selected-job-types");
    $(this.menuTarget).foundation();
    document.addEventListener('click', this.handleClickOutside.bind(this));
    this.extractAndApplySelection();
    this.updateApplyButtonVisibility();
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside.bind(this));
  }

  handleClickOutside(event) {
    const dropdown = this.menuTarget;
    const jobBox = document.querySelector(".recruiters-job-dropdown");

    if (!dropdown.contains(event.target) && !jobBox.contains(event.target)) {
      this.closeDropdown();
    }
  }

  openDropdown() {
    this.selectedJobs = document.querySelectorAll(".selected-job");
    this.jobBox.classList.add("selected");
    this.jobTextElement.classList.add("opacity");
    this.jobIcon.classList.add("opacity");
    
    if (this.selectedJobs.length > 0) {
      this.selectedJobs.forEach(function(job) {
        job.classList.add("opacity");
      });
    }
    $(this.menuTarget).foundation('open');
  }

  closeDropdown() {
    this.selectedJob = document.querySelectorAll(".selected-job");
    this.jobTextElement.classList.remove("opacity");
    this.jobIcon.classList.remove("opacity");
    this.jobBox.classList.remove("selected");
    if (this.selectedJobs.length > 0) {
      this.selectedJobs.forEach(function(job) {
        job.classList.remove("opacity");
      });
    }
    $(this.menuTarget).foundation('close');
  }

  clearSelections() {
    this.clearData();
    this.showSelectedJobs();
    this.updateButtonText();
  }
  clearData() {
    this.jobTextElement.classList.remove("opacity");
    this.jobIcon.classList.remove("opacity");
    this.selectedItems = [];
    this.lastRemoved = false;
    document.querySelectorAll('.job-item').forEach(jobItem => {
      jobItem.classList.remove('selected');
    });
  }

  resetOptions() {
    this.clearData();
    this.updateButtonText(true);

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete("placement_types[]");
    window.location.href = currentUrl.toString();
    this.jobBox.classList.remove("selected");
    $(this.roleTarget).foundation('close');
  }

  clearOptions() {
    this.clearSelections();
    this.jobBox.classList.remove("selected");
    $(this.menuTarget).foundation('close');
  }

  showSelectedJobs() {
    const urlParams = new URLSearchParams(window.location.search);
    const placementTypes = urlParams.getAll("placement_types[]");
    
    if (placementTypes.length === 0) return;

    placementTypes.forEach(jobItem => {
        this.selectedItems.push(jobItem);
        const jobItems = document.querySelector(`[data-job-type='${decodeURIComponent(jobItem)}']`);
        jobItems.classList.add('selected');
    });
  }

  toggleJobSelection(event) {
    const jobType = event.currentTarget.dataset.jobType;
    const jobItem = event.currentTarget;
    const selectedIndex = this.selectedItems.indexOf(jobType);

    if (selectedIndex > -1) {
      this.selectedItems.splice(selectedIndex, 1);
      jobItem.classList.remove('selected');
    } else {
      this.selectedItems.push(jobType);
      jobItem.classList.add('selected');
    }
    this.updateApplyButtonVisibility();
  }

  updateApplyButtonVisibility() {
    const applyButton = this.element.querySelector('.jobs-apply-button');
    applyButton.classList.toggle('disabled', this.selectedItems.length === 0);
  }

  updateButtonText(isClear = false) {
    const selectedJobCount = isClear ? 0 : this.selectedItems.length;

    if (selectedJobCount === 0) {
      this.jobTextElement.innerHTML = "Any";
      this.jobTextComponent.style.marginLeft = "0.438rem";
      this.jobBox.style.justifyContent = "center";
      document.querySelector("button.dotted-box span img").style.display = "inline";
    } else {
      this.lastRemoved = true;
      const selectedJobsToShow = this.selectedItems.slice(0, 3);
      const jobText = selectedJobsToShow.map(job => `<span class="selected-job">${job}</span>`).join(" ");

      this.jobTextElement.classList.remove("opacity");
      this.jobIcon.classList.remove("opacity");
      this.jobTextComponent.style.width = "100%";
      this.jobBox.style.justifyContent = "flex-start";
      this.jobBox.style.padding = "0.2rem 0.23rem";
      this.jobTextComponent.style.display = "flex";
      this.jobTextComponent.style.marginLeft = "0";
      this.jobTextComponent.style.gap = "0.2rem";
      document.querySelector("button.dotted-box span img").style.display = "none";
      this.jobTextElement.innerHTML = jobText;
    }
  }

  applySelection() {
    this.restoreEffects();
  
    if (Array.isArray(this.selectedItems)) {
      const placementTypes = [...new Set(this.selectedItems)];
      const queryString = placementTypes
        .map(type => `placement_types%5B%5D=${encodeURIComponent(type)}`)
        .join('&');
  
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.delete('placement_types[]');
      placementTypes.forEach(type => {
        currentUrl.searchParams.append('placement_types[]', type);
      });
      window.location.href = currentUrl.toString();
    }
  }

  restoreEffects() {
    if (this.selectedItems.length === 0 && this.lastRemoved === true) {
      this.lastRemoved = false;
      this.updateButtonText();
      this.jobTextElement.classList.remove("opacity");
      this.jobIcon.classList.remove("opacity");
      this.jobBox.classList.remove("selected");
      $(this.menuTarget).foundation('close');
    }else if(this.selectedItems.length === 0) {
      $(this.menuTarget).foundation('open');
    }else {
      this.lastRemoved = false;
      this.updateButtonText();
      this.jobTextElement.classList.remove("opacity");
      this.jobIcon.classList.remove("opacity");
      this.jobBox.classList.remove("selected");
      $(this.menuTarget).foundation('close');
    }
  }

  applySelectionLandingPage(event) {
    this.restoreEffects();
    this.jobTypesRedirection(event)
  }

  jobTypesRedirection(event) {
    const jobType = event.currentTarget.dataset.jobType;
    const jobItem = event.currentTarget;

    if (jobType) {
      const selectedIndex = this.selectedItems.indexOf(jobType);

      if (selectedIndex > -1) {
          this.selectedItems.splice(selectedIndex, 1);
          jobItem.classList.remove('selected');
      } else {
          this.selectedItems.push(jobType);
          jobItem.classList.add('selected');
      }
    }

    if (Array.isArray(this.selectedItems)) {
      const placementTypes = this.selectedItems;
      const queryString = placementTypes.map(type =>
        `placement_types%5B%5D=${encodeURIComponent(type)}`
      ).join('&');
      const url = `/healthcare_top_recruiters?${queryString}`;
      window.location.href = url;
    }
  }

  landingPageJobTypesRedirection(event) {
    this.selectedItems = [];
    const jobType = event.currentTarget.dataset.jobType;
    const jobItem = event.currentTarget;
    const currentUrl = new URL(window.location.href);
    const newUrl = `${currentUrl.origin}/healthcare_top_recruiters`;
    if (jobType === 'Local Contract' || jobType === 'Staff Position') {
      window.location.href = newUrl;
      return;
    }
    if (jobType) {
      const selectedIndex = this.selectedItems.indexOf(jobType);
  
      if (selectedIndex > -1) {
        this.selectedItems.splice(selectedIndex, 1);
        jobItem.classList.remove('selected');
      } else {
        this.selectedItems.push(jobType);
        jobItem.classList.add('selected');
      }
    }
    if (Array.isArray(this.selectedItems)) {
      const placementTypes = this.selectedItems;
      const queryString = placementTypes
        .map(type => `placement_types%5B%5D=${encodeURIComponent(type)}`)
        .join('&');
  
      const currentUrl = new URL(window.location.href);
      let basePath = currentUrl.pathname;
      if (basePath.includes('/gr_marketplace')) {
        basePath = basePath.replace('/gr_marketplace', '/healthcare_top_recruiters');
      }
      else {
        basePath = '/healthcare_top_recruiters';
      }
      currentUrl.searchParams.forEach((value, key) => {
        if (key.startsWith("placement_types")) {
          currentUrl.searchParams.delete(key);
        }
      });
      const newUrl = `${currentUrl.origin}${basePath}?${queryString}`;
      window.location.href = newUrl;
    }
  }

  extractAndApplySelection() {
    const urlParams = new URLSearchParams(window.location.search);
    const placementTypes = urlParams.getAll("placement_types[]");
    if (placementTypes.length != 0) {
      placementTypes.forEach(type => {
        const jobItems = document.querySelectorAll(`[data-job-type='${decodeURIComponent(type)}']`);
        jobItems.forEach(jobItem => {
          jobItem.classList.add('selected');
          this.selectedItems.push(decodeURIComponent(type));
        });
      });
      this.applySelectionLandingPage();
    }
  }
}
